

@font-face {
  font-family: 'blockzone';
  src: url('assets/fonts/blockzone.woff2') format('woff2'), url('assets/fonts/blockzone.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #000;
  font-family: 'blockzone';
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center; 
  /* text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.screen {
  display: block;
  width: 90vw;
  max-width: 100vh;

  image-rendering: pixelated;
  padding-bottom: 1vh;

}

.content {
  max-height: 100vh;
}

.fullwidth {
  display: flex;
  flex-direction: column; 
  align-items: center;
  margin: 0 auto;         
 
}

.reveal-image {
    overflow: hidden;
    display: inline-block;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.center {
  max-height: 20vh; 
  height: auto; 
}

/* INPUT */

.inputcontainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5px;
  padding: 0px 0px;
  box-sizing: border-box;
  container-type: inline-size;
}


#inputbox {
  background-color: #000;
  color: #fff;
  box-shadow: none;
  border: none;
  font-family: 'blockzone';
  width: 100%;
  margin: 0;
}

input {
  background-color: #000;
  color: #fff;
  box-shadow: none;
  border: none;
  font-family: 'blockzone';
  font-size: 2.225cqi;
  padding: 0;
  margin: 0;
}

input:focus {
  border: none;
  outline: none;
}