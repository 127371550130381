.reveal-image {
    display: none; /* Initially hide the image */
    animation: reveal-animation 1s step-end forwards;
}

@keyframes reveal-animation {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }
    4% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }
    8% {
        clip-path: polygon(0% 4%, 100% 4%, 100% 0%, 0% 0%);
    }
    12% {
        clip-path: polygon(0% 8%, 100% 8%, 100% 0%, 0% 0%);
    }
    16% {
        clip-path: polygon(0% 12%, 100% 12%, 100% 0%, 0% 0%);
    }
    20% {
        clip-path: polygon(0% 16%, 100% 16%, 100% 0%, 0% 0%);
    }
    24% {
        clip-path: polygon(0% 20%, 100% 20%, 100% 0%, 0% 0%);
    }
    28% {
        clip-path: polygon(0% 24%, 100% 24%, 100% 0%, 0% 0%);
    }
    32% {
        clip-path: polygon(0% 28%, 100% 28%, 100% 0%, 0% 0%);
    }
    36% {
        clip-path: polygon(0% 32%, 100% 32%, 100% 0%, 0% 0%);
    }
    40% {
        clip-path: polygon(0% 36%, 100% 36%, 100% 0%, 0% 0%);
    }
    44% {
        clip-path: polygon(0% 40%, 100% 40%, 100% 0%, 0% 0%);
    }
    48% {
        clip-path: polygon(0% 44%, 100% 44%, 100% 0%, 0% 0%);
    }
    52% {
        clip-path: polygon(0% 48%, 100% 48%, 100% 0%, 0% 0%);
    }
    56% {
        clip-path: polygon(0% 52%, 100% 52%, 100% 0%, 0% 0%);
    }
    60% {
        clip-path: polygon(0% 56%, 100% 56%, 100% 0%, 0% 0%);
    }
    64% {
        clip-path: polygon(0% 60%, 100% 60%, 100% 0%, 0% 0%);
    }
    68% {
        clip-path: polygon(0% 64%, 100% 64%, 100% 0%, 0% 0%);
    }
    72% {
        clip-path: polygon(0% 68%, 100% 68%, 100% 0%, 0% 0%);
    }
    76% {
        clip-path: polygon(0% 72%, 100% 72%, 100% 0%, 0% 0%);
    }
    80% {
        clip-path: polygon(0% 76%, 100% 76%, 100% 0%, 0% 0%);
    }
    84% {
        clip-path: polygon(0% 80%, 100% 80%, 100% 0%, 0% 0%);
    }
    88% {
        clip-path: polygon(0% 84%, 100% 84%, 100% 0%, 0% 0%);
    }
    92% {
        clip-path: polygon(0% 88%, 100% 88%, 100% 0%, 0% 0%);
    }
    96% {
        clip-path: polygon(0% 92%, 100% 92%, 100% 0%, 0% 0%);
    }
    100% {
        clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
    }
}
